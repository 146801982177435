import SmoothScroll from 'smooth-scroll';

export function init() {
  const scroll = new SmoothScroll('a[href*="#"]', {
    topOnEmptyHash: true,
    clip: true,
    easing: 'easeInOutCubic',
    updateURL: false,
    popstate: false,
    offset: 180,
  });

  scroll.init();
}
