import tippy from 'tippy.js';

export function init() {
  console.log("yes")
  const commonTippyConfig = {
    allowHTML: true,
    interactive: true,
    maxWidth: 'none',
    arrow: false,
    interactiveDebounce: 15,
    interactiveBorder: 10,
  };

  const tippyConfig = {
    ...commonTippyConfig,
    content(reference) {
      const target = reference.getAttribute('data-submenu-target');
      const template = document.querySelector('[data-submenu="' + target + '"]');
      return template.innerHTML;
    },
  };

  const tippyMenuConfig = {
    ...tippyConfig,
    animation: 'shift-away-subtle',
    placement: 'bottom-start',
    offset: [0, 20],
    theme: 'menu',
    onShown: () => {
      tippy('[data-submenu-toggle="level-2"]', {
        ...tippyConfig,
        animation: 'shift-away-subtle',
        placement: 'right-start',
        offset: [-20, 20],
        theme: 'submenu',
      });
    },
  };

  const tippyInstances = tippy('[data-submenu-toggle="level-1"]', tippyMenuConfig);

  // Function to enable/disable tooltips based on screen size
  function handleScreenSizeChange(mq) {
    if (mq.matches) {
      // Enable tooltips on larger screens
      tippyInstances.forEach((instance) => instance.enable());
    } else {
      // Disable tooltips on smaller screens
      tippyInstances.forEach((instance) => instance.disable());
    }
  }

  // Initial check and setup
  const mediaQuery = window.matchMedia('(min-width: 1020px)');
  handleScreenSizeChange(mediaQuery);

  // Add event listener for screen size changes
  mediaQuery.addEventListener('change', (e) => handleScreenSizeChange(e.currentTarget));

  // Add click event listeners to submenu toggles
  const submenuToggles = document.querySelectorAll('[data-submenu-toggle]');
  submenuToggles.forEach(toggle => toggle.addEventListener('click', handleToggleClick));

  function handleToggleClick(e) {

    if (mediaQuery.matches) return;

    e.preventDefault();

    const toggle = e.currentTarget;
    const arrow = toggle.querySelector('[data-arrow]');
    const level = toggle.getAttribute('data-submenu-toggle');
    const target = toggle.getAttribute('data-submenu-target');
    const submenu = document.querySelector(`[data-submenu="${target}"]`);

    if (e.target != arrow) {
      window.location.href = toggle.href;
      return;
    }

    submenu.classList.toggle('hidden');
    arrow.classList.toggle('rotate-180');

    submenuToggles.forEach(_toggle => {
      const _arrow = _toggle.querySelector('[data-arrow]');
      const _level = _toggle.getAttribute('data-submenu-toggle');
      const _target = _toggle.getAttribute('data-submenu-target');
      const _submenu = document.querySelector(`[data-submenu="${_target}"]`);

      if (_submenu.classList.contains('mobile-open')) return;

      if (level === 'level-1' && _level === 'level-2') {
        _submenu.classList.add('hidden');
        _arrow.classList.remove('rotate-180');
      }

      if (level === _level) {
        const id = _submenu.getAttribute('data-submenu');

        if (id !== target) {
          _submenu.classList.add('hidden');
          _arrow.classList.remove('rotate-180');
        }
      }
    });
  }
}
