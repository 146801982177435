export default class Tabs {
  constructor(node) {
    this.node = node;
    this.triggers = Array.from(node.querySelectorAll('[data-tabs-target]'));
    this.panels = Array.from(node.querySelectorAll('[data-tabs-panel]'));

    this.activeClass = 'is-active';

    this.handleClick = this.handleClick.bind(this);
    this.triggers.forEach((trigger) => {
      trigger.addEventListener('click', this.handleClick);
    });
  }

  handleClick(e) {
    e.preventDefault();
    const target = e.currentTarget;
    const id = target.dataset.tabsTarget;
    this.setActiveTab(id, target);
  }

  resetTabs() {
    this.panels.forEach((panel) => {
      panel.setAttribute('aria-hidden', 'true');
      panel.classList.remove(this.activeClass);
    });

    this.triggers.forEach((trigger) => {
      trigger.setAttribute('aria-selected', 'false');
      trigger.classList.remove(this.activeClass);
    });
  }

  setActiveTab(id, trigger) {
    this.resetTabs();
    const tab = this.node.querySelector(id);

    if (tab) {
      tab.setAttribute('aria-hidden', 'false');
      tab.classList.add(this.activeClass);

      trigger.setAttribute('aria-selected', 'true');
      trigger.classList.add(this.activeClass);
    }
  }
}
